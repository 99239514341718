import React from "react"

export const ThreeSvg = () => (
  <svg
    width="192"
    height="192"
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="192" height="192" rx="96" fill="#DDEDFB" />
    <path
      d="M94.4526 144.178C87.9045 144.178 81.4013 143.321 74.9429 141.607C68.4845 139.803 63.0128 137.276 58.5278 134.028L67.0045 117.247C70.5925 119.864 74.7635 121.939 79.5176 123.473C84.2717 125.006 89.0706 125.773 93.9144 125.773C99.3861 125.773 103.692 124.69 106.831 122.525C109.971 120.36 111.54 117.383 111.54 113.593C111.54 109.984 110.15 107.142 107.369 105.067C104.589 102.992 100.104 101.955 93.9144 101.955H83.9577V87.474L110.195 57.5655L112.617 65.4148H63.2371V47.8215H129.166V62.0315L103.064 91.94L92.0307 85.5794H98.3546C109.926 85.5794 118.672 88.1958 124.592 93.4287C130.512 98.6615 133.472 105.383 133.472 113.593C133.472 118.916 132.082 123.924 129.301 128.615C126.52 133.216 122.26 136.961 116.519 139.848C110.778 142.735 103.423 144.178 94.4526 144.178Z"
      fill="#EEF5FB"
    />
  </svg>
)
