// extracted by mini-css-extract-plugin
export var item = "moreBenefitsInvitefriend-module--item--TPMi6";
export var item1 = "moreBenefitsInvitefriend-module--item1--QE1rv";
export var item2 = "moreBenefitsInvitefriend-module--item2--MOGOo";
export var item3 = "moreBenefitsInvitefriend-module--item3--X+5ty";
export var item4 = "moreBenefitsInvitefriend-module--item4--vKjHn";
export var itemSubTitle = "moreBenefitsInvitefriend-module--itemSubTitle--c-cWo";
export var itemTitle = "moreBenefitsInvitefriend-module--itemTitle--ZvG1U";
export var section = "moreBenefitsInvitefriend-module--section--au+gV";
export var title = "moreBenefitsInvitefriend-module--title--VqvYc";
export var wrap = "moreBenefitsInvitefriend-module--wrap--+usGb";
export var wrapper = "moreBenefitsInvitefriend-module--wrapper--BxP+B";