import React from "react"

export const OneSvg = () => (
  <svg
    width="196"
    height="196"
    viewBox="0 0 196 196"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="196" height="196" rx="98" fill="#DDEDFB" />
    <path
      d="M89.3794 148.639V55.3753L100.188 65.5761H67.762V46.6318H114.394V148.639H89.3794Z"
      fill="#EEF5FB"
    />
  </svg>
)
