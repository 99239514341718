import React, { ReactElement } from "react"
import { FourSvg, OneSvg, ThreeSvg, TwoSvg } from "./images"

export interface BenefitsItem {
  svg: ReactElement
  title: ReactElement
  subTitle: ReactElement
}

const inviteFriendItems = [
  {
    svg: <OneSvg />,
    title: <>Заполните заявку </>,
    subTitle: (
      <>
        на карту «Халва» <br /> прямо сейчас
      </>
    ),
  },
  {
    svg: <TwoSvg />,
    title: (
      <>
        Получите Халву <br /> у курьера
      </>
    ),
    subTitle: <>или в офисе банка</>,
  },
  {
    svg: <ThreeSvg />,
    title: (
      <>
        Совершайте покупки <br /> заемными средствами
      </>
    ),
    subTitle: (
      <>
        в магазинах-партнерах <br />
        на общую сумму от 1000₽ <br />в первые 10 дней
      </>
    ),
  },
  {
    svg: <FourSvg />,
    title: (
      <>
        Получите 1000 баллов <br />
        кэшбэка
      </>
    ),
    subTitle: <>на карту «Халва»</>,
  },
]

const rosticsItems = [
  {
    svg: <OneSvg />,
    title: <>Заполните заявку </>,
    subTitle: (
      <>
        на карту «Халва» <br /> прямо сейчас
      </>
    ),
  },
  {
    svg: <TwoSvg />,
    title: (
      <>
        Получите Халву <br /> у курьера
      </>
    ),
    subTitle: <>или в офисе банка</>,
  },
  {
    svg: <ThreeSvg />,
    title: (
      <>
        Совершите первую <br /> покупку в ROSTIC’S
      </>
    ),
    subTitle: <>от 700 ₽ до 01.03.2025</>,
  },
  {
    svg: <FourSvg />,
    title: (
      <>
        Получите 700 <br /> баллов кэшбэка <br /> и&nbsp;7 промокодов* на&nbsp;кофе
        за&nbsp;1&nbsp;₽**
      </>
    ),
    subTitle: <>на карту «Халва»</>,
  },
]

const cashbackItems = [
  {
    svg: <OneSvg />,
    title: (
      <>
        Оформите карту
        <br />
        «Халва»
      </>
    ),
    subTitle: (
      <>
        в период с 15.10.2024 <br />
        по 15.11.2024
      </>
    ),
  },
  {
    svg: <TwoSvg />,
    title: (
      <>
        Совершите покупку
        <br />
        любыми средствами
      </>
    ),
    subTitle: (
      <>
        в магазине-партнере <br />
        на сумму от 2000₽ <br />с 15.10.2024 по 30.11.2024
      </>
    ),
  },
  {
    svg: <ThreeSvg />,
    title: <>Примените промокод</>,
    subTitle: (
      <>
        в мобильном приложении <br />
        Халвы к покупке от 2000₽ <br />с 15.10.2024 по 30.11.2024
      </>
    ),
  },
  {
    svg: <FourSvg />,
    title: (
      <>
        Получите кэшбэк <br />
        1000 рублей
      </>
    ),
    subTitle: (
      <>
        он поступит на бонусный <br />
        счет вашей Халвы
      </>
    ),
  },
]

const privateHalva = [
  {
    svg: <OneSvg />,
    title: (
      <>
        Зайдите в приложение или нажмите{" "}
        <a href="https://halvacard.ru/lk" target="_blank" rel="noreferrer">
          сюда
        </a>
      </>
    ),
    subTitle: <>Раздел «Халва – Предложения – Пригласите и заработайте»</>,
  },
  {
    svg: <TwoSvg />,
    title: (
      <>
        Отправьте <br /> ссылку другу
      </>
    ),
    subTitle: (
      <>
        И попросите его оформить <br /> по ней Халву
      </>
    ),
  },
  {
    svg: <ThreeSvg />,
    title: <>Друг должен сделать покупку заемными средствами</>,
    subTitle: (
      <>
        В течение 10 дней <br /> в магазинах-партнерах
      </>
    ),
  },
  {
    svg: <FourSvg />,
    title: <>Вы получите 1500 баллов, каждый друг — 1000 баллов</>,
    subTitle: <>Лимитов на отправку ссылок друзьям нет</>,
  },
]

export const items: Record<
  "inviteFriend" | "privateHalva" | "cashback" | "rostics",
  BenefitsItem[]
> = {
  inviteFriend: inviteFriendItems,
  privateHalva,
  cashback: cashbackItems,
  rostics: rosticsItems,
}
