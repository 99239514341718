import React from "react"

export const TwoSvg = () => (
  <svg
    width="192"
    height="192"
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="192" height="192" rx="96" fill="#DDEDFB" />
    <path
      d="M62.8434 144.178V129.968L99.2564 95.4586C102.133 92.8422 104.246 90.4964 105.595 88.4213C106.944 86.3462 107.843 84.4516 108.292 82.7374C108.832 81.0231 109.101 79.4443 109.101 78.0007C109.101 74.2114 107.798 71.3243 105.19 69.3394C102.673 67.2643 98.9417 66.2268 93.9967 66.2268C90.0408 66.2268 86.3545 66.9937 82.938 68.5274C79.6114 70.0612 76.7793 72.4521 74.4416 75.7001L58.5278 65.4148C62.1242 60.0015 67.159 55.7159 73.6325 52.5582C80.1059 49.4004 87.5683 47.8215 96.0197 47.8215C103.033 47.8215 109.146 48.9944 114.361 51.3402C119.666 53.5957 123.756 56.7986 126.634 60.9488C129.6 65.099 131.084 70.0612 131.084 75.8354C131.084 78.9029 130.679 81.9705 129.87 85.038C129.151 88.0153 127.623 91.1731 125.285 94.5113C123.037 97.8495 119.711 101.594 115.305 105.744L85.0958 134.299L80.915 126.314H134.186V144.178H62.8434Z"
      fill="#EEF5FB"
    />
  </svg>
)
