import React from "react"

export const FourSvg = () => (
  <svg
    width="196"
    height="196"
    viewBox="0 0 196 196"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="196" height="196" rx="98" fill="#DDEDFB" />
    <path
      d="M52.4609 126.526V111.209L99.1423 48.8179H123.19L77.4991 111.209L66.3239 107.977H144.268V126.526H52.4609ZM105.791 147.182V126.526L106.498 107.977V89.5688H128.141V147.182H105.791Z"
      fill="#EEF5FB"
    />
  </svg>
)
